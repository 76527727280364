<template>
  <component :is="visitData === undefined ? 'div' : 'div'">

    <!-- Alert: No item found -->
    <b-alert
      v-if="!visitData"
      variant="primary"
    >
      <div class="alert-body">
        No visit selected to edit. Check
        <b-link
          class="alert-link"
          :to="{ name: 'patient-list'}"
        >
          No visit
        </b-link>
        for more visits.
      </div>
    </b-alert>

    <!-- Show Info About Appointment -->
    <b-card
      v-if="visitData && visitData.Patient"
    >
      <!-- Header: Basic Info -->
      <div class="d-flex mt-1 mb-2">
        <feather-icon
          icon="ClipboardIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Appointment Info
        </h4>
      </div>
      <b-row>
        <b-col
          cols="12"
          xl="5"
        >
          <table class="mt-1 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75 text-success"
                />
                <span class="font-weight-bold">Doctor Name</span>
              </th>
              <td class="pb-50">
                <router-link
                  v-if="visitData && visitData.Doctor"
                  :to="{ name: 'doctor-view', params: { id: visitData.Doctor.Id } }"
                  class="font-weight-bold d-block text-nowrap"
                  target="_blank"
                >
                  {{ visitData.Doctor.Name }}
                </router-link>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75 text-danger"
                />
                <span class="font-weight-bold">Patient Name</span>
              </th>
              <td class="pb-50">
                <router-link
                  v-if="visitData && visitData.Patient"
                  :to="{ name: 'patient-view', params: { id: visitData.Patient.Id } }"
                  class="font-weight-bold d-block text-nowrap"
                  target="_blank"
                >
                 {{ visitData.Patient.Name }}
                </router-link>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="DollarSignIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Invoice</span>
              </th>
              <td class="pb-50">
              <router-link
                 v-if="visitData && visitData.Appointment"
                 :to="{ name: 'invoice-operation', query: { appointment_id: visitData.Appointment.Id } }"
                 class="font-weight-bold d-block text-nowrap"
                 target="_blank"
                >
                 View Invoice Details
                </router-link>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Appointment Day</span>
              </th>
              <td
                v-if="visitData && visitData.Appointment"
                class="pb-50"
              >
                 {{ visitData.Appointment.Day }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Appointment From</span>
              </th>
              <td
                v-if="visitData && visitData.Appointment"
                class="pb-50"
              >
                 {{ visitData.Appointment.From }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="ClockIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Appointment To</span>
              </th>
              <td
                v-if="visitData && visitData.Appointment"
                class="pb-50"
              >
                 {{ visitData.Appointment.To }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="visitData && visitData.Patient"
    >

     <!-- Visit Info: Input Fields -->
     <validation-observer
       #default="{ handleSubmit }"
       ref="refFormObserver"
     >
       <!-- Form -->
       <b-form
         class="p-1"
         v-if="visitData"
         @submit.prevent="handleSubmit(onUpdateVisitData)"
         @reset.prevent="resetForm"
       >
         <!-- Header: Basic Info -->
         <div class="d-flex mt-1 mb-2">
           <feather-icon
             icon="ClipboardIcon"
             size="19"
           />
           <h4 class="mb-0 ml-50">
             Visit Info
           </h4>
         </div>

         <!-- Field: Chief Complaint -->
         <b-row>
           <b-col
             cols="12"
             md="12"
           >
             <validation-provider
               #default="validationContext"
               name="Chief Complaint"
               rules=""
             >
               <b-form-group
                 label="Chief Complaint"
                 label-for="chief-complaint"
               >
                 <b-form-textarea
                   id="chief-complaint"
                   v-model="visitData.ChiefComplaint"
                   :state="getValidationState(validationContext)"
                   trim
                   placeholder=""
                   rows="3"
                 />
                 <b-form-invalid-feedback>
                   {{ validationContext.errors[0] }}
                 </b-form-invalid-feedback>
               </b-form-group>
             </validation-provider>
           </b-col>
         </b-row>

         <!-- Field: Diagnosis -->
         <b-row>
           <b-col
             cols="12"
             md="12"
           >
             <validation-provider
               #default="validationContext"
               name="Diagnosis"
               rules=""
             >
               <b-form-group
                 label="Diagnosis"
                 label-for="diagnosis"
               >
                 <b-form-textarea
                   id="diagnosis"
                   v-model="visitData.Diagnosis"
                   :state="getValidationState(validationContext)"
                   trim
                   placeholder=""
                   rows="3"
                 />
                 <b-form-invalid-feedback>
                   {{ validationContext.errors[0] }}
                 </b-form-invalid-feedback>
               </b-form-group>
             </validation-provider>
           </b-col>
         </b-row>

         <!-- Field: X-Ray -->
         <b-row>
           <b-col
             cols="12"
             md="12"
           >
             <validation-provider
               #default="validationContext"
               name="X-Ray"
               rules=""
             >
               <b-form-group
                 label="X-Ray"
                 label-for="x-ray"
               >
                 <b-form-textarea
                   id="x-ray"
                   v-model="visitData.XRay"
                   :state="getValidationState(validationContext)"
                   trim
                   placeholder=""
                   rows="3"
                 />
                 <b-form-invalid-feedback>
                   {{ validationContext.errors[0] }}
                 </b-form-invalid-feedback>
               </b-form-group>
             </validation-provider>
           </b-col>
         </b-row>

         <b-row>
             <b-col cols="12">
               <hr>
             </b-col>
         </b-row>
         <b-row>
           <b-col cols="12" md="12" v-if="visitData && visitData.TreatmentNotes" >
               Treatment Notes:
               <b-container class="bv-example-row mt-1">
                   <b-row class="d-flex justify-content-center mt-1" align-v="stretch">
                       <div
                           v-for="treatment in visitData.TreatmentNotes.slice(0, 16)"
                           :key="'Div_' +treatment.Id"
                         >
                             <img :src="treatment.ImageUrl"
                                  :alt="treatment.Label + ' ' + treatment.Code"
                                  :id="'Image_' + treatment.Id"
                                  :treatment-id="treatment.Id"
                                  @click="treatmentNoteFire(treatment, $event)" />
                         </div>
                   </b-row>
               </b-container>

               <b-container class="bv-example-row  mb-2">
                   <b-row class="d-flex justify-content-center mb-2" align-v="stretch">
                       <div
                         v-for="treatment in visitData.TreatmentNotes.slice(16, 32)"
                         :key="'Div_' +treatment.Id"
                       >
                           <img :src="treatment.ImageUrl"
                                :alt="treatment.Label + ' ' + treatment.Code"
                                :id="'Image_' + treatment.Id"
                                :treatment-id="treatment.Id"
                                @click="treatmentNoteFire(treatment, $event)" />
                       </div>
                   </b-row>
               </b-container>

               <div v-for="treatment in visitData.TreatmentNotes" :key="'TreatmentNote_' + treatment.Id">
                 <b-form-group :label="treatment.Label + ' ' + treatment.Code" :label-for="treatment.Code" v-if="treatment.IsVisible">
                  <b-form-textarea
                    :id="'Note_' + treatment.Id"
                    v-model="treatment.Note"
                    trim
                    placeholder=""
                    rows="3"
                  />
                 </b-form-group>
               </div>

           </b-col>
         </b-row>

         <!-- Action Buttons -->
         <div class="d-flex mt-3">
           <b-button
             variant="primary"
             class="mr-1"
             :block="$store.getters['app/currentBreakPoint'] === 'xs'"
             type="submit"
           >
              Save Changes
           </b-button>
           <b-button
              variant="outline-secondary"
              type="reset"
             :block="$store.getters['app/currentBreakPoint'] === 'xs'"
             @click="$router.push({ name: 'visit-list' })"
           >
             Cancel
           </b-button>
         </div>
       </b-form>
     </validation-observer>
    </b-card>

  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BFormGroup, BFormInput, BForm, BFormTextarea, BFormInvalidFeedback, BButton, BRow, BCol, BTable, BContainer,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import VisitStoreModule from '@/store/main/VisitStoreModule'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// Validation
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BTable,
    BContainer,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      alphaNum,
      email,
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
    }
  },
  methods: {
    treatmentNoteFire(treatment) {
      // console.log(treatment.Note)
      // console.log(event.target.attributes['treatment-id'].value)
      this.visitData.TreatmentNotes.forEach(ele => {
        if (ele.Note.trim() === '' && ele.Id !== treatment.Id) {
          // eslint-disable-next-line no-param-reassign
          ele.IsVisible = false
        }
      })
      // eslint-disable-next-line no-param-reassign
      treatment.IsVisible = !treatment.IsVisible
    },
  },
  setup() {
    const VISIT_STORE_MODULE_NAME = 'visit-store'
    // Register module
    if (!store.hasModule(VISIT_STORE_MODULE_NAME)) store.registerModule(VISIT_STORE_MODULE_NAME, VisitStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VISIT_STORE_MODULE_NAME)) store.unregisterModule(VISIT_STORE_MODULE_NAME)
    })

    //----------------------------------------------------------------------------------------------------------------------------
    // const blankVisitData = {
    //   ChiefComplaint: '',
    //   Treatment: '',
    //   Diagnosis: '',
    //   XRay: '',
    // }
    // const visitData = ref(JSON.parse(JSON.stringify(blankVisitData)))

    //----------------------------------------------------------------------------------------------------------------------------
    const toast = useToast()
    const visitData = ref(null)

    if (router.currentRoute.query.appointment_id === undefined || router.currentRoute.query.appointment_id === null) {
      visitData.value = undefined
      return {
        visitData,
      }
    }

    store.dispatch('visit-store/getVisitByAppointmentId', { id: router.currentRoute.query.appointment_id })
      .then(response => {
        visitData.value = response.data.Data
      })
      .catch(error => {
        if (error.response.status === 404) {
          visitData.value = undefined
        }
      })

    const onUpdateVisitData = () => {
      const updatedVisitDataObject = {}
      updatedVisitDataObject.AppointmentId = visitData.value.Appointment.Id
      updatedVisitDataObject.VisitId = visitData.value.Id
      updatedVisitDataObject.ChiefComplaint = visitData.value.ChiefComplaint
      updatedVisitDataObject.Diagnosis = visitData.value.Diagnosis
      updatedVisitDataObject.XRay = visitData.value.XRay
      updatedVisitDataObject.TreatmentNotes = []
      visitData.value.TreatmentNotes.forEach(treatment => {
        if (treatment.Note.trim() !== '' && treatment.IsVisible) {
          updatedVisitDataObject.TreatmentNotes.push({
            Id: 0,
            TreatmentNoteId: treatment.Id,
            Note: treatment.Note,
          })
        }
      })

      store.dispatch('visit-store/updateVisit', updatedVisitDataObject)
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Operation Done',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'You have successfully update visit!',
            },
          })
          router.push({ name: 'visit-list' })
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Operation Failed',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Error happened when updating visit!',
            },
          })
        })
    }

    const resetvisitData = () => {
      // eslint-disable-next-line no-param-reassign
      visitData.value = JSON.parse(JSON.stringify(visitData.value))
    }

    //----------------------------------------------------------------------------------------------------------------------------

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetvisitData)

    return {
      refFormObserver,
      getValidationState,
      onUpdateVisitData,
      resetForm,
      visitData,
    }
  },
}
</script>

<style>

</style>
